import clsx from "clsx";
import { marked } from "marked";
import dompurify from "isomorphic-dompurify";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document } from "@contentful/rich-text-types";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  markdown?: string;
  richText?: { json: Document };
}

export const ContentfulTextContainer = ({
  className,
  markdown,
  richText,
  ...props
}: Props) => {
  const classes = clsx(
    "font-ModernGothic md:text-base whitespace-pre-line",
    "[&_ul]:list-disc [&_ul]:pl-4 [&_li]:mb-2 [&_ul]:whitespace-normal",
    "[&_ol]:list-decimal [&_ol]:list-inside [&_li]:mb-2 [&_ol]:whitespace-normal",
    "[&_a]:text-[#0000EE] [&_a]:underline",
    className
  );

  if (richText !== null && richText !== undefined) {
    return (
      <div className={classes} {...props}>
        {documentToReactComponents(richText.json)}
      </div>
    );
  } else {
    return (
      <div
        className={classes}
        dangerouslySetInnerHTML={{
          __html: dompurify.sanitize(marked.parse(markdown ?? "")),
        }}
        {...props}
      />
    );
  }
};
